import { useEffect, useState } from "react";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import DateSelectForm from "../form/input/DateSelectForm";
import FilterSearchExportExcelButton from "../input/FilterSearchExportExcelButton";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";
import { debounce } from "lodash";

/**
 * @COMPONENT_NAME DailyWarehouseStockHistory
 * @DESCRIPTION 일별 품목별 창고별 재고 이력 조회 화면
 */

function DailyWarehouseStockHistory ( props ) {

    const [search, setSearch] = useState();

    const [parameter, setParameter] = useState({
        date : new Date()
    });

    const [filter, setFilter] = useState ();

    const [api, setApi] = useState();

    const [height, setHeight] = useState(0);

    const [warehouseGrid, setWareHouseGrid] = useState();


    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    useEffect(() => {
    
        if (warehouseGrid) {

            warehouseGrid.selectionChanged.addHandler(debounce((grid, event) => {

                if (grid.selectedItems && grid.selectedItems[0]) {
                    setParameter((prev)=> ({...prev, wareHouseCode : grid.selectedItems[0].wareHouseCode}))

                } else {

                };

            }, 10));

            warehouseGrid.itemsSourceChanged.addHandler(debounce((grid, event) => {

                if (grid.selectedItems && grid.selectedItems[0]) {

                    setParameter((prev)=> ({...prev, wareHouseCode : grid.selectedItems[0].wareHouseCode}))

                } else {


                };

            }, 10));
        }
    
        }, [warehouseGrid])

    useEffect(() => {

        window.addEventListener('resize', handleResize);
        handleResize();

        return ( () => {
            window.removeEventListener('resize', handleResize);
        } );

    }, []);


    useEffect(() => {
    
        if (parameter && api ) {
            api.refresh();
        }
    
    }, [parameter, api])

    const onSearchChangeValue = (value) => {

        setSearch(() => {
            let searchValues = {
                search : value
            };
            return searchValues;
        });
    }

    const onFilterChangeValue = (value) => {
        setFilter(value);
    }

    const onChangeDate = (value) => {
        
        setParameter((prev) => {
            let searchValues = {
                ...prev,
                date : value
            };
            return searchValues;
        });
    }

    const onApiInitialized = (api) => {

        setApi(api);

    };

    const onWareHouseGridInitialized = (grid) => {
        grid.selectionMode = "Row";
        setWareHouseGrid(grid);
    }

    return (

        <div style={{ height: height }} >

            <ReflexContainer orientation="vertical"> 

                <ReflexElement flex={0.2}>
                    <BasicCrudScreenType 
                        id="MomSysUdtWareHouse"
                        view="MomSysVwWareHouse"
                        onInitialized = {onWareHouseGridInitialized}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                    />
                </ReflexElement>

                <ReflexSplitter style={{ width: layoutConf.reflex.splitterWidth }} />
                <ReflexElement flex={0.8}>
                    {
                        parameter?.wareHouseCode
                        ?
                            <BasicCrudScreenType
                                id="VwDailyWarehouseStockHistory" 
                                view="VwDailyWarehouseStockHistory" 
                                noCreate
                                noEdit
                                noDelete
                                noExcel
                                onSearchChangeValue={onSearchChangeValue}
                                onFilterChangeValue={onFilterChangeValue}
                                onApiInitialized = {onApiInitialized}
                                onModifyViewUrl = {(url) => `${url}/wareHouses/${parameter?.wareHouseCode}/histories/${parameter?.date?.toLocaleDateString('sv-SE')}`}
                                onAppendButtonAtFirst={() =>
                                    <>
                                        <BasicCrudScreenActionItemLayout>
                                            <div style={{ display: "inline-block" }} >
                                            <span
                                                style={{
                                                    display: "inline-block",
                                                    height: "16px",
                                                    paddingLeft: "15px",
                                                    paddingTop: "7px",
                                                    marginRight: "2px",
                                                }}
                                            >
                                                조회 일자 :
                                            </span>
                                                <DateSelectForm
                                                    id={"VwDailyWarehouseStockHistory-condition-value"}
                                                    value={parameter.date}
                                                    onChange={onChangeDate}
                                                />
                                            </div>
                                        </BasicCrudScreenActionItemLayout>
                                    </>
                                }
                            onAppendButtonInFrontOfSearch={
                                () => (
                                    <>
                                        <BasicCrudScreenActionItemLayout>
                                            <FilterSearchExportExcelButton 
                                                search={search}
                                                filter={filter}
                                                exportName = "VwDailyWarehouseStockHistory"
                                                url = {`VwDailyWareHouseStockHistory/warehouses/${parameter?.wareHouseCode}/histories/${parameter?.date?.toLocaleDateString('sv-SE')}/excelDownload`}
                                            />
                                        </BasicCrudScreenActionItemLayout>
                                    </>
                                )
                            }
                        />
                        :
                        <></>
                    }
                </ReflexElement>
            </ReflexContainer>
        </div>
    );

}
export default DailyWarehouseStockHistory;
