import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { FlexGridXlsxConverter } from "@grapecity/wijmo.grid.xlsx";

import SaveToExeclButtonLayout from "../layout/SaveToExcelButtonLayout";

import getSchema from "../../lib/api/getSchema";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { debounce } from 'lodash';

/**
 * @COMPONENT_NAME DailyStockHistoryExcelButton
 * @DESCRIPTION 일별 품목별 재고 엑셀 다운로드 받을수 있게 하는 컴포넌트
 *
 * @PROPS [전달받는 props]
 *    - {Type} [propName] - [설명]
 *    - {string} id - 컴포넌트의 고유 아이디
 *    - {string} view - 엑셀 다운로드에 사용할 뷰의 이름
 *    - {string} fileName - 다운로드될 엑셀 파일의 이름 (기본값은 테이블 이름)
 *    - {boolean} includeRowHeaders - 행 헤더 포함 여부
 *    - {object} searchValues - 검색값으로, 엑셀 다운로드에 필요한 조회 시간
 *    - {object} exportFormatItem - 엑셀 내 항목 포맷을 커스터마이즈 할 함수
 *
 * @PASSTHROUGH_PROPS [하위 컴포넌트로 전달되는 props]
 *    - {Type} [propName] - [설명]
 *      예: {string} label - 하위 버튼 컴포넌트에 전달될 버튼 라벨
 *    - {string} color - 버튼 색상
 *
 * @EXAMPLES [사용 예제] (선택 사항)
 * ```jsx
 * <DailyStockHistoryExcelButton
        id={id}
        view={view}
        color="primary"
        searchValues={searchValues}
    />
 * ```
 */

function DailyStockHistoryExcelButton(props) {
    const { t } = useTranslation();

    const { id, view, fileName, exportFormatItem, includeRowHeaders, searchValues, ...others } = props;

    const [viewSchema, setViewSchema] = useState();
    const [dateDataArray, setDateDataArray] = useState([]);

    const [grid, setGrid] = useState();

    const [api, setApi] = useState();

    const [, setFiileDownload] = useState(false);

    useEffect(() => {
        (async () => {
            if (grid) {
                grid.refreshed.addHandler(
                    debounce((grid, event) => {
                        setFiileDownload((fileDownload)=>{
                            if(fileDownload){
                                FlexGridXlsxConverter.saveAsync(
                                    grid,
                                    {
                                        includeColumnHeaders: true,
                                        includeCellStyles: true,
                                        includeRowHeaders: includeRowHeaders,
                                        formatItem: exportFormatItemWithDate,
                                    },
                                    fileName || t(`tableName.${id}`) + ".xlsx"
                                );
                            }
                            return false
                        });
                    }, 100)
                );
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);

    const onGridInitialized = (grid) => {

        grid.selectionMode = "Row";

        setGrid(grid);

    };

    const onApiInitialized = (api) => {

        setApi(api);

    };

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema(view);

            if (mounted) {
                setViewSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, [view]);

    useEffect(() => {
        if (viewSchema) {
            const arr = [];
            for (const column of viewSchema.columns) {
                if (column?.grid?.type?.startsWith("date")) {
                    arr.push(column.name);
                }
            }

            setDateDataArray(arr);
        }
    }, [viewSchema]);

    const exportFormatItemWithDate = (args) => {
        const textContent = args.getFormattedCell().textContent;
        const { panel, col } = args;

        if (dateDataArray.includes(panel.columns[col].binding)) {
            args.xlsxCell.value = textContent;
        } else if (exportFormatItem) {
            exportFormatItem(args);
        } else {
            args.xlsxCell.value = textContent;
        }
    };

    const saveToExcel = () => {
        console.log('click')
        setFiileDownload(true)
        if(api){
            api.refresh();
        }        
    };

    const onModifyUrl = (url) => {

        let newUrl = `${url}/excelDownload/${searchValues.startTime}`
        return newUrl;
    };

    return (
        <>
            <SaveToExeclButtonLayout id={id + "save-to-execl-button"} onClick={saveToExcel} {...others} />
            <div style={{display:'none'}}>
                <BasicCrudScreenType
                    id={id}
                    // table={table}
                    view={view}
                    noCreate
                    // noSearch
                    noEdit
                    noDelete
                    noExcel
                    noPagination={true}
                    onModifyViewUrl={(url) => onModifyUrl(url)}
                    onInitialized={onGridInitialized}
                    onApiInitialized={onApiInitialized}
                />
            </div>
        </>
    );
}

export default DailyStockHistoryExcelButton;
