
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from "react";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import getNewDateObj from "../../lib/common/getNewDateObj";
import DateSelectForm from "../form/input/DateSelectForm";
import AccessControl from "../functional/AccessControl";
import DailyStockHistoryExcelButton from "../input/DailyStockHistoryExcelButton";
import getSchema from "../../lib/api/getSchema";

/**
 * @COMPONENT_NAME DailyStockHistory
 * @DESCRIPTION 일별 품목별 재고 이력 조회 화면
 */

function DailyStockHistory(props) {
    const id = "DailyStockHistory"
    const view = "VwDailyWholeMaterialStockHistory"

    const today = new Date();

    const [grid, setGrid] = useState();

    const [api, setApi] = useState();

    const [schema, setSchema] = useState();

    const [, setIsEmpty] = useState(true);

    const [searchValues, setSearchValues] = useState({
        materialType: "",
        endTime: getNewDateObj(today),
        startTime: getNewDateObj(today.setDate(today.getDate() - 1)),
    });


    useEffect(() => {

        let mounted = true;

        (async () => {

            let schema = await getSchema(view);
            
            if (mounted) {
                setSchema(schema);
            }

        })();

        return (() => {
            mounted = false;
        });


    }, []);

    useEffect(() => {

        if (searchValues && api) {

            api.refresh();
        }


    }, [searchValues, api])

    const onGridInitialized = (grid) => {

        grid.selectionMode = "Row";

        setGrid(grid);

    };

    const onApiInitialized = (api) => {

        setApi(api);

    };

    const onModifyUrl = (url) => {

        let newUrl = `${url}/${searchValues.startTime}`
        return newUrl;
    };

    const onLoadData = (data) => {

        setIsEmpty(data.length < 1 ? true : false)

    }

    const onChangeCondition1Value = (value) => {

        setSearchValues((prevValues) => {

            let searchValues = {
                ...prevValues,
                startTime: value,

            };
            return searchValues;
        })


    }

    return (
        <BasicCrudScreenType
            id={id}
            view={view}
            noCreate
            noEdit
            noDelete
            noExcel
            onModifyViewUrl={(url) => onModifyUrl(url)}
            onInitialized={onGridInitialized}
            onApiInitialized={onApiInitialized}
            onLoadData={onLoadData}
            onAppendHeaderAtFirst={() => (
                <>
                    <BasicCrudScreenActionItemLayout>

                        <div style={{ display: "inline-block" }} >
                            <span
                                style={{
                                    display: "inline-block",
                                    height: "16px",
                                    paddingLeft: "15px",
                                    paddingTop: "7px",
                                    marginRight: "2px",
                                }}
                            >
                                조회 일자 :
                            </span>

                            <DateSelectForm
                                id={"DailyStockSummary-condition1-value"}
                                value={searchValues.startTime}
                                onChange={onChangeCondition1Value}
                            />

                        </div>

                    </BasicCrudScreenActionItemLayout>
                </>
            )}
            onAppendButtonInFrontOfSearch={() => (
                <BasicCrudScreenActionItemLayout>
                    <AccessControl
                        accessTarget={schema && schema.name}
                        accessMethod="SAVETOEXCEL"
                    >
                        <DailyStockHistoryExcelButton
                            id={id}
                            view={view}
                            color="primary"
                            searchValues={searchValues}
                        />
                    </AccessControl>
                </BasicCrudScreenActionItemLayout>
            )}
        />
    );

}

export default DailyStockHistory;
