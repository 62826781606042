import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from "recoil";
import SaveToExcelButtonLayout from "../layout/SaveToExcelButtonLayout";
import apiClient from '../../lib/common/apiClient';
import notificationState from "../../states/notificationState";

/**
 * @COMPONENT_NAME FilterSearchExportExcelButton
 * @DESCRIPTION  백앤드에서 검색 및 필터링된 데이터를 엑셀 저장하는 버튼 컴포넌트
 *
 * @PROPS [전달받는 props]
 *      {string} search - 검색어
 *      {object} filter - 필터링 정보
 *      {string} exportName - 엑셀 파일명
 *      {string} url - API URL
 *
 * @PASSTHROUGH_PROPS [하위 컴포넌트로 전달되는 props]
 *    {...any} others - 기타 속성
 */

function FilterSearchExportExcelButton ( props ) {

    const {search, filter, exportName, url, ...others} = props;
    const { t } = useTranslation ();
    const setNotification = useSetRecoilState(notificationState);

    const successNotification = () => {
        setNotification({
            severity : "success",
            msg : t("success.fileDownloadSuccess"),
            open : true
        });
    };

    const failureNotification = (reason) => {
        setNotification({
            severity : "error",
            msg : t("error.fileDownloadFailure", {reason: reason.response ? reason.response.data : reason}),
            open : true
        });
    };

    const doHandleFileStream = (result, exportName) => {

        const targetFileName = `${t(`excelExportName.${exportName}`)}.xlsx`;

        const blob = new Blob([result.data]);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = targetFileName;
        link.click();
        window.URL.revokeObjectURL(link.href);
    };

    const onSaveToExcel = () => {
        
        let filterSearchUrl = `/api/${url}`;
        const queryParams = new URLSearchParams();

        for (let key in search) {
            if (search[key]) {
                queryParams.append(key, search[key]);
            }
        }
        

        if (filter) {
            for (const data of filter) {
                queryParams.append(data.binding, data.condition1.value);
            }
        }

        const queryString = queryParams.toString();

        if (queryString) {
            filterSearchUrl += `?${queryString}`;
        }

        apiClient
            .get(filterSearchUrl, { responseType: "blob" })
            .then((result) => {
                doHandleFileStream(result, t(`${exportName}`));
                successNotification(result);
            })
            .catch((reason) => failureNotification(reason));
    }

    return (
        <>
            <SaveToExcelButtonLayout id={"save-to-excel-button"} color="primary" onClick = {onSaveToExcel} {...others}/>
        </>
    );
}

export default FilterSearchExportExcelButton;
