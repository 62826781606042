import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

import getSchema from "../../lib/api/getSchema";
import BadItemCreateButton from "../input/BadItemCreateButton";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";

import AccessControl from "../functional/AccessControl";
import notificationState from "../../states/notificationState";
import "./gridEvent.css"
import SelfInspectionDeleteButton from "../input/SelfInspectionDeleteButton";
import InspectionItemMoveButton from "../input/InspectionItemMoveButton";
import defineConf from "../../config/defineConf";

function IncomingInspectionTarget(props) {
    const { id, masterGridSetting, masterApiSetting, setTargetGrid, badItemDisabled, setBadItemDisabled } = props;

    const [schema, setSchema] = useState();
    const [deleteSchema, setDeleteSchema] = useState();
    const [api, setApi] = useState();
    const [grid, setGrid] = useState();
    const [onSelectGrid, setOnSelectGrid] = useState();

    const setNotification = useSetRecoilState(notificationState);

    const { t } = useTranslation();

    useEffect(() => {
        let mounted = true;
        async function schemaSetting() {
            let schema = await getSchema("IncomingInspectionTargetView");
            let deleteSchema = await getSchema("MomSysInspection");

            if(mounted) {
                setSchema(schema);
                setDeleteSchema(deleteSchema);
            }
        }

        schemaSetting();

        return (() => {
            mounted = false;
        });
    }, []);

    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        masterGridSetting(grid.selectedItems[0]);
                        setOnSelectGrid(grid.selectedItems[0]);
                    } else {
                        masterGridSetting([]);
                        setOnSelectGrid(undefined);
                    };
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        masterGridSetting(grid.selectedItems[0]);
                        setOnSelectGrid(grid.selectedItems[0]);
                    } else {
                        masterGridSetting([]);
                        setOnSelectGrid(undefined);
                    };
                });
            }
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);

    const onApiInitialized = (api) => {
        setApi(api);
        masterApiSetting(api);
    };

    const onInitialized = (grid) => {
        grid.selectionMode = "Row";
        setGrid(grid);
        setTargetGrid(grid);
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifyWarning = (msg) => {
        notifyWarning(msg);
    };

    const badItemButtonDisabled = () => {
        let disabled = true;
        
        if(onSelectGrid) {
            if(onSelectGrid.inspectionResultState === '불량' || onSelectGrid.inspectionResultState === '부분 불량') {
                disabled = false;
            }
        }

        if(badItemDisabled === false) {
            disabled = false;
            setBadItemDisabled(true);
        }

        return disabled;
    }

    return (
        <>
            <BasicCrudScreenType
                id={id}
                table="IncomingInspectionTargetView"
                view="IncomingInspectionTargetView"
                onApiInitialized={onApiInitialized}
                onInitialized={onInitialized}
                headerTitle={t("term.inspectionTarget")}
                noCreate
                noEdit
                noDelete
                noExcel
                onAppendButtonInFrontOfSearch={() => (
                    <>
                        <BasicCrudScreenActionItemLayout>
                            <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                                <InspectionItemMoveButton
                                    id = "InspectionItemMove"
                                    schema = {schema}
                                    title={t("term.inspectionItemMove")}
                                    refresh = {api}
                                    onSelectGrid = {onSelectGrid}
                                    onSuccess = {onSuccess}
                                    createButtonName = {"inspectionItemMove"}
                                    disabled={onSelectGrid ? false : true}
                                />
                            </AccessControl>
                            <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                                <BadItemCreateButton
                                    id = "BadItemCreate"
                                    schema = {schema}
                                    refresh = {api}
                                    onSelectGrid = {onSelectGrid}
                                    onSuccess = {onSuccess}
                                    createButtonName = {"badItemCreate"}
                                    disabled={badItemButtonDisabled()}
                                />
                            </AccessControl>
                            <AccessControl accessTarget={schema && schema.name} accessMethod="DELETE">
                                <SelfInspectionDeleteButton
                                    id={id}
                                    schema={deleteSchema}
                                    title={t("term.momSysInspectionDelete")}
                                    grid={grid}
                                    refresh={api}
                                    color="secondary"
                                    onSuccess={onSuccess}
                                    onFailure={onFailure}
                                    onPartialFailure={onNotifyWarning}
                                    onSelectGrid={onSelectGrid}
                                    disabled={onSelectGrid ? false : true}
                                    inspectionType={defineConf.inspectionType.INPUT_INSPECTION}
                                />
                            </AccessControl>
                        </BasicCrudScreenActionItemLayout>
                    </>
                )}
            />
        </>
    );
}

export default IncomingInspectionTarget;
