/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Snackbar, Divider, InputLabel, FormControl, TextField } from "@material-ui/core";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { Alert } from "@material-ui/lab";
import { Autocomplete } from "@material-ui/lab";
import "react-reflex/styles.css";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import AutoForm from "../form/functional/AutoForm";

import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";
import EguLotListForProcess from "../screens/EguLotListForProcess";
import EguFailedLot from "../screens/EguFailedLot";
import getExpirationDate from "../../lib/utils/getExpirationDate";

const getSchema = (isTenantUseMediumUnit) => {

    let schema = {
        name: "Production",
        type: "BASE TABLE",
        searchAll: false,
        formGroups: ["soInfo", "bobMaterialInfo", "lotInfo", "machineInfo"],

        columns: [
            {
                name: "orderBuyerId",
                type: "varchar(50)",
                nullable: "NO",
                key: "PRI",
                readonly: true,
                default: null,
                formGroup: 0,
                formOrder: 1,
                formItemType: "text",
            },
            {
                name: "bopMaterialCode",
                type: "varchar(50)",
                nullable: "NO",
                key: "PRI",
                readonly: true,
                default: null,
                formGroup: 1,
                formOrder: 3,
                formItemType: "text",
            },
            {
                name: "bopMaterialCodeVer",
                type: "int(11)",
                nullable: "NO",
                key: "PRI",
                readonly: true,
                default: "0",
                formGroup: 1,
                formOrder: 4,
                formItemType: "text",
            },
            {
                name: "bopMaterialName",
                type: "varchar(50)",
                nullable: "NO",
                key: "PRI",
                readonly: true,
                default: null,
                formGroup: 1,
                formOrder: 5,
                formItemType: "text",
            },
            {
                name: "lotNo",
                type: "int(11)",
                nullable: "NO",
                key: "PRI",
                readonly: true,
                default: "0",
                formGroup: 2,
                formOrder: 6,
                formItemType: "text",
            },
            {
                name: "lineNo",
                type: "varchar(50)",
                nullable: "NO",
                key: "PRI",
                readonly: true,
                default: null,
                formGroup: 2,
                formOrder: 7,
                formItemType: "text",
            },
            {
                name: "resultQty",
                type: "float",
                nullable: "NO",
                required: true,
                default: null,
                formGroup: 3,
                formOrder: 1,
                formItemType: "float",
            },
            {
                name: "materialQty",
                type: "number",
                nullable: "NO",
                required: isTenantUseMediumUnit,
                default: null,
                formGroup: 3,
                formOrder: 10,
                formItemType: "number",
                hideInForm : !isTenantUseMediumUnit,
                validation : {
                    msg: "error.numberRequired",
                    type: "regexp",
                    value: "^[0-9]*$",
                },
            },
            {
                name: "afterWareHouseCode",
                type: "varchar(50)",
                nullable: "NO",
                default: null,
                formGroup: 3,
                formOrder: 20,
                hideInForm : true,
                formItemType: "autocomplete",
                codeInfo : {
                    tableName: "MomSysVwStoreAndLineWareHouse",
                    nameColumn: ["wareHouseCode","wareHouseName"],
                    valueColumn: "wareHouseCode",
                    useEmptyValue: true,
                    emptyName: "term.none",
                    sortByName: true,
                }
            },
            {
                name: "machineCode",
                type: "varchar(50)",
                nullable: "NO",
                default: null,
                formGroup: 3,
                formOrder: 30,
                formItemType: "autocomplete",
                codeInfo: {
                    tableName: "MomSysMstMachine",
                    nameColumn: ["machineCode", "machineName"],
                    valueColumn: "machineCode",
                    seperator: ": ",
                    useEmptyValue: true,
                    emptyName: "term.none",
                    sortByName: true,
                },
            },
            {
                name: "shipWareHouseCode",
                type: "varchar(50)",
                nullable: "NO",
                formGroup: 2,
                formOrder: 40,
                disabled: true,
                hideInForm: true,
                formItemType: "text",
            },

            {
                name: "processCode",
                type: "varchar(50)",
                nullable: "NO",
                readonly: true,
                default: null,
                formGroup: 2,
                formOrder: 12,
                formItemType: "text",
            },
            {
                name: "processName",
                type: "varchar(50)",
                nullable: "NO",
                key: "PRI",
                readonly: true,
                default: null,
                formGroup: 2,
                formOrder: 13,
                formItemType: "text",
            },
            {
                name: "qty",
                type: "float",
                nullable: "NO",
                key: "",
                readonly: true,
                default: null,
                formGroup: 2,
                formOrder: 14,
                formItemType: "text",
                validation: {
                    msg: "error.floatRequired",
                    type: "regexp",
                    value: "^[+-]?([0-9]*[.])?[0-9]+$",
                },
            },
            {
                name: "productionEndDate",
                type: "date",
                nullable: "NO",
                default: null,
                formGroup: 3,
                formOrder: 50,
                formItemType: "date",
            },
            {
                name: "expirationDate",
                type: "DATETIME(6)",
                nullable: "NO",
                default: null,
                formGroup: 3,
                formOrder: 60,
                hideInForm: true,
                formItemType: "date",
            },
            {
                name: "manufacturedDate",
                type: "DATETIME(6)",
                nullable: "NO",
                required: true,
                default: null,
                formGroup: 3,
                formOrder: 70,
                hideInForm: true,
                formItemType: "date",
            },
            {
                name: "productFailureWareHouse",
                type: "varchar(50)",
                nullable: "NO",
                default: null,
                formGroup: 3,
                formOrder: 80,
                formItemType: "autocomplete",
                codeInfo: {
                    tableName: "MomSysVwFailureWareHouse",
                    nameColumn: ["wareHouseCode", "wareHouseName"],
                    valueColumn: "wareHouseCode",
                    seperator: ": ",
                    useEmptyValue: true,
                    emptyName: "term.none",
                    sortByName: true,
                },
            },
            {
                name: "materialFailureWareHouse",
                type: "varchar(50)",
                nullable: "NO",
                default: null,
                formGroup: 3,
                formOrder: 90,
                formItemType: "autocomplete",
                codeInfo: {
                    tableName: "MomSysVwFailureWareHouse",
                    nameColumn: ["wareHouseCode", "wareHouseName"],
                    valueColumn: "wareHouseCode",
                    seperator: ": ",
                    useEmptyValue: true,
                    emptyName: "term.none",
                    sortByName: true,
                },
            },
            {
                name: "inputWorkingTime",
                type: "int(11)",
                nullable: "NO",
                default: null,
                formGroup: 3,
                formOrder: 100,
                formItemType: "text",
                validation: {
                    msg: "error.floatRequired",
                    type: "regexp",
                    value: "^[+-]?([0-9]*[.])?[0-9]+$",
                },
            },
            {
                name: "addInfo",
                type: "varchar(50)",
                nullable: "NO",
                key: "",
                readonly: false,
                default: null,
                formGroup: 3,
                formOrder: 110,
                formItemType: "text",
            }
        ],
    };
    return schema;
}

function EguPopCompleteProcessDialog(props) {
    const { t } = useTranslation();
    const [instance, setInstance] = useState();
    const [formData, setFormData] = useState({});
    const [schema, setSchema] = useState();

    const [lotGrid, setLotGrid] = useState();
    const [failedLotList, setFailedLotList] = useState([]);
    const [materials, setMaterials] = useState([]);

    const [lastProcess, setLastProcess] = useState();
    const [disabled, setDisabled] = useState ( false );
    const [workers, setWorkers] = useState();
    const [plannedWorker, setPlannedWorker] = useState([]);
    
    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });
    
    const [isTenantUseMediumUnit, setIsTenantUseMediumUnit] = useState(false);

    const [currentLotInfo, setCurrentLotInfo] = useState();

    const {
        id,
        lotInfo,

        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onClose,
        onInitialized,
        onSuccess,

        ...others
    } = props;


    const notifyWarning = (msg) => {
        console.warn(msg);

        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        console.error(msg);

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    useEffect(() => {
        let mounted = true;

        (async () => {
            let result = await apiClient.get(`api/User`);

            let workers = result.data.data.map((data) => {
                // 표시이름이 없는 경우 label에 username 할당되도록 수정 - 표시이름 없는 사용자가 있는 경우 작업자 선택 시 오류 수정
                return { label: data["fullName"] || data["username"], value: data["username"] };
            });

            if (mounted) {
                setWorkers(workers);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {

        const schema = getSchema(isTenantUseMediumUnit);

        setSchema(schema);

    }, [isTenantUseMediumUnit]);
    
    useEffect(() => {

        setFormData((prevFormData) => {
            return { ...prevFormData, workers: plannedWorker };
        });

    }, [plannedWorker]);

    useEffect(() => {
        
            (async () => {

                if (lotInfo) {

                    if(lotInfo.bopMaterialCode && lotInfo.bopMaterialCodeVer) {

                        let { mediumUnitQty : materialQty} = (await apiClient.get(`/api/MomSysMstMaterial/${lotInfo.bopMaterialCode}/${lotInfo.bopMaterialCodeVer}`)).data;

                        let isTenantUseMediumUnit = false;
                    
                        if(materialQty) {

                            isTenantUseMediumUnit = (await apiClient.get('/api/getTenantOptionValue/schemaControl.showInForm/materialQty')).data?.optionValue === "true" ? true: false;
                            
                            setIsTenantUseMediumUnit(isTenantUseMediumUnit);

                            setFormData((prevFormData) => {
                                let newFormData = {
                                    ...prevFormData,    
                                    materialQty: materialQty,
                                    mediumUnitQty : materialQty,
                                };
        
                                return newFormData;
                            });

                        } else {

                            setIsTenantUseMediumUnit(false);
                        }

                        let materials = await apiClient.get(`/api/MomSysVwMaterialsForProcessOnLine/${lotInfo.lotNo}`);
    
                        if (materials.data.data.length > 0) {
                            setMaterials(materials.data.data);
                        } else {
                            setMaterials([]);
                        }
    
                        let isLastProcess = await apiClient.get(
                            `/api/MomSysUdtBopSeq/${lotInfo.bopMaterialCode}/${lotInfo.bopMaterialCodeVer}/${lotInfo.processCode}`
                        );
                        setLastProcess(isLastProcess?.data?.data);
    
                        let currentLotInfo;
    
                        if (lotInfo.lotNo) {
                            
                            currentLotInfo = await apiClient.get(`/api/MomSysSgtWipLot/${lotInfo.lotNo}`);
                            setCurrentLotInfo(currentLotInfo?.data);
                        }
                        
                        setFormData((formData) => {

                            let newFormData = {
                                ...formData,
    
                                orderBuyerId: lotInfo.orderBuyerId,
                                companyName: lotInfo.companyName,
    
                                bopMaterialCode: lotInfo.bopMaterialCode,
                                bopMaterialCodeVer: lotInfo.bopMaterialCodeVer,
                                bopMaterialName: lotInfo.bopMaterialName,
    
                                lotNo: lotInfo.lotNo,
                                extLotNo: lotInfo.extLotNo,
                                lineNo: lotInfo.lineNo,
                                machineName: lotInfo.machineName,
                                machineCode: lotInfo.machineCode,
                                processCode: lotInfo.processCode,
                                processName: lotInfo.processName,
    
                                otherFeatures: undefined,
                                productFailureWareHouse: undefined,
                                materialFailureWareHouse: undefined,
                                parentPlanId: lotInfo.parentPlanId,
                                plannedQty: lotInfo.plannedQty,
                                shipWareHouseCode: lotInfo.wareHouseCode,
    
                                qty: parseFloat(currentLotInfo?.data?.qtyQueued),
                                // resultQty: isTenantUseMediumUnit ? parseFloat(currentLot?.data?.qtyCurrent) * parseFloat(materialQty) : parseFloat(currentLot?.data?.qtyCurrent),
                                // 생산 수량의 기본 값은 최초에는 지시 수량과 동일해야 하고 원자재 수량은 절수에 의해 계산 되어야 함
                                resultQty: currentLotInfo?.data?.qtyQueued,
                                materialQty: isTenantUseMediumUnit ? Math.ceil(currentLotInfo?.data?.qtyQueued / formData.mediumUnitQty) : materialQty,
                            };
    
                            return newFormData;
                        });
    
                        if (lotInfo.plannedWorkerId && workers) {
                            let plannedWorkers = lotInfo.plannedWorkerId.split(",");
                            let palnnedWorkerDefaultValues = workers.filter((worker) => {
                                if (plannedWorkers.includes(worker.value)) return worker;
    
                                return false;
                            });
    
                            setPlannedWorker(palnnedWorkerDefaultValues);
                        } else {
                            setPlannedWorker([]);
                        }
                    }
                }
            })();
    }, [lotInfo]);

    useEffect(() => {
        if (lastProcess) {

            setSchema((prevSchema) => {

                for (let column of prevSchema.columns) {
                    switch (column.name) {
                        case "shipWareHouseCode":
                        case "expirationDate":
                        case "manufacturedDate":
                        case "afterWareHouseCode":
                            column.hideInForm = false;

                            break;

                        default:
                            break;
                    }
                }

                let newSchema = {
                    ...prevSchema,
                };

                return newSchema;
            });
            setFormData((prevFormData) => {
                let newFormData = {
                    ...prevFormData,
                    manufacturedDate: new Date(),
                    expirationDate: getExpirationDate(lotInfo.standardExpirationDate, new Date()),
                    afterWareHouseCode : currentLotInfo && currentLotInfo.wareHouseCode
                };

                return newFormData;
            });

        } else {

            setSchema((prevSchema) => {
                for (let column of prevSchema.columns) {
                    switch (column.name) {
                        case "shipWareHouseCode":
                        case "expirationDate":
                        case "manufacturedDate":
                        case "afterWareHouseCode":
                            column.hideInForm = true;

                            break;

                        default:
                            break;
                    }
                }

                let newSchema = {
                    ...prevSchema,
                };

                return newSchema;
            });

            setFormData((prevFormData) => {
                let newFormData = {
                    ...prevFormData,
                    manufacturedDate: null,
                    expirationDate: null,
                    afterWareHouseCode: null,
                };

                return newFormData;
            });
            
        }
    }, [lastProcess, currentLotInfo]);

    const onDialogInitialized = (instance) => {
        setInstance(instance);

        if (onInitialized) {
            onInitialized(instance);
        }
    };

    const onApiSuccess = (lotInfo, result) => {

        if (onSuccess) {
            onSuccess(result.data);
        }

        setDisabled(false);

        instance.hide();
        
    };

    const onApiFailure = (lotInfo, reason) => {
        notifyFailure(
            t("error.productionStartFail", { lotNo: lotInfo.lotNo }) + ` : ${reason.response ? reason.response.data : JSON.stringify(reason)}`
        );

        setDisabled(false);
    };


    const checkManufacturedDateValidation = (manufacturedDate) => {

        let today = new Date();

        if(manufacturedDate) {

            if(manufacturedDate > today ) {
                
                notifyWarning(t("warning.manufacturedDateNotOverToday"));
                return false;
            }
        }

        return true;
    }

    const checkItemValidataion = (schema, lotInfo, lotGrid, failedLotList, materials, formData) => {

        for (const column of schema.columns) {
            let [valid, msg] = checkValidation("create", column, column.validation, formData[column.name] || "");

            if (!valid) {
                notifyWarning(msg);
                setDisabled(false)
                return false;
            }
        }

        if (formData.qty <= 0) {
            notifyWarning(t("error.emptyOrNegativeProductionQty"));
            setDisabled(false)
            return false;
        }

        let materialMap = {};

        if (lotGrid) {

            for (const lot of lotGrid.rows) {
                
                let key = `[${lot.dataItem.materialCode}][${lot.dataItem.materialCodeVer}]`;

                if (materialMap[key]) {
                    materialMap[key] = {
                        ...materialMap[key],
                        consumed: parseFloat((parseFloat(materialMap[key].consumed) + parseFloat(lot.dataItem.qtyConsumed)).toFixed(4)),
                    };
                } else {
                    materialMap[key] = {
                        materialCode: lot.dataItem.materialCode,
                        materialCodeVer: lot.dataItem.materialCodeVer,
                        consumed: parseFloat(parseFloat(lot.dataItem.qtyConsumed).toFixed(4)),
                        qtyRequired: parseFloat(parseFloat(lot.dataItem.requiredQty).toFixed(4)),
                    };
                }
            }

            for (const material of materials) {
                let key = `[${material.materialCode}][${material.materialCodeVer}]`;

                if (!materialMap[key]) {
                    notifyWarning(t("error.materialsForProductionNotTakeOver") + ` : ${material.materialCode}/${material.materialCodeVer} ${material.materialName}`);
                    setDisabled(false)
                    return false;
                }

                // if (materialMap[key].consumed < materialMap[key].qtyRequired) {
                //     notifyWarning(t("error.LotMaterialsConsumeQtyRequiredQtyLack"));
                //     return false;
                // }
            }
        }

        return true;
    };

    const onOk = (schema, lotInfo, lotGrid, failedLotList, materials, formData) => {

        setDisabled(true);

        let inputLotList = [];

        if (lotGrid) {

            // let isMaterialFailure = lotGrid.rows.some((row) => Number(row.dataItem.notInputFailedQty) !== 0);

            // if (isMaterialFailure && !formData.materialFailureWareHouse) {
            //     notifyWarning(t("warning.materialWareHouseRequiredNotInputFailedQty"));
            //     setDisabled(false);
            //     return;
            // }
            /* 
            // 제품 불량창고 선택 안할시 자동 폐기로 기능 수정

            // let isProductFailure = failedLotList.length > 0 ? true : false;

            // if (isProductFailure && !formData.productFailureWareHouse) {
            //     notifyWarning(t("warning.productWareHouseRequiredNotInputFailedQty"));
            //     setDisabled(false);
            //     return;
            // }
            */
            for (const lot of lotGrid.rows) {
                inputLotList.push({
                    lotNo: lot.dataItem.lotNo,
                    extLotNo: lot.dataItem.extLotNo,
                    materialName: lot.dataItem.materialName,
                    materialCode: lot.dataItem.materialCode,
                    materialCodeVer: lot.dataItem.materialCodeVer,
                    qtyRequired: parseFloat(lot.dataItem.requiredQty),
                    qtyInput: parseFloat(lot.dataItem.inputQty),
                    qtyConsumed: Math.round(lot.dataItem.qtyConsumed * 10000) / 10000,
                    inputFailedQty: Math.round(lot.dataItem.inputFailedQty * 10000) / 10000,
                    notInputFailedQty: Math.round(lot.dataItem.notInputFailedQty * 10000) / 10000,
                });
            }
        }

        if(!checkManufacturedDateValidation(formData.manufacturedDate)){
            console.error("manufacturedDate Validation false");
            setDisabled(false);
            return;
        };

        if (!checkItemValidataion(schema, lotInfo, lotGrid, failedLotList, materials, formData)) {
            console.error("Form Validation Failed");
            setDisabled(false);
            return;
        }
        if (schema.validation) {
            let [valid, msg] = checkConditionWithMsg(formData, schema.validation);

            if (!valid) {
                notifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                setDisabled(false);
                return;
            }
        }

        let url = `/api/Task/${lotInfo.planId}/end`;

        let parsedBonus;
        let parsedLoss;

        let failedProductQty = failedLotList.reduce((accumulator, current) => parseFloat(accumulator) + parseFloat(current.qty), [0]);

        if (parseFloat(formData.qty) > parseFloat(formData.resultQty) + parseFloat(failedProductQty)) {
            parsedLoss = parseFloat(formData.qty) - (parseFloat(formData.resultQty) + parseFloat(failedProductQty));
        } else if (parseFloat(formData.qty) < parseFloat(formData.resultQty) + parseFloat(failedProductQty)) {
            parsedBonus = parseFloat(formData.resultQty) + parseFloat(failedProductQty) - parseFloat(formData.qty);
        }
        
        parsedBonus = isNaN(parsedBonus) ? 0 : parsedBonus;
        parsedLoss = isNaN(parsedLoss) ? 0 : parsedLoss;

        let apiData = {
            ...formData,
            addInfo: formData.addInfo,
            machineCode: formData.machineCode,
            inputLotList: inputLotList,
            failedLotList: failedLotList,
            bonus: parsedBonus,
            loss: parsedLoss,
            workers: plannedWorker,
            failedProductQty : failedProductQty
        };
        // build base api url
        let form = new FormData();

        let json = {};
        let deleted = {};

        for (const formItem in apiData) {
            json[formItem] = apiData[formItem];
        }
        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));
        apiClient
            .post(url, form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then ( ( result ) => {
                onApiSuccess ( lotInfo, result );
            } )
            .catch ( ( reason ) => {
                onApiFailure ( lotInfo, reason );
        } );
    };

    const onCancel = () => {
        setFailedLotList([]);

        instance.hide();
    };

    const onCloseNotification = (e, reason) => {
        if (reason === "timeout" || !reason) {
            setNotification((notification) => {
                return { ...notification, open: false };
            });
        }
    };

    const onCloseDialog = () => {
        if (onClose) {
            onClose();
        }

        setNotification((notification) => {
            return { ...notification, open: false };
        });

        setFormData((formData) => {
            let newFormData = {
                ...formData, 
                addInfo: null, 
                inputWorkingTime : null
            };

            delete newFormData.productionEndDate;

            return newFormData;
        });

        setFailedLotList([]);
    };

    const onAutoFormChange = (formData) => {
        setDisabled(false);
        setFormData(formData);
    };

    const onChangeFailedLotList = (failedLotList) => {
        setDisabled(false);
        setFailedLotList(failedLotList);
    };

    const onLotGridInitialized = (lotGrid) => {
        if (lotGrid) {
            lotGrid.loadedRows.addHandler((grid, event) => {
                let materialMap = {};

                for(const material of materials) {
                    let key = material.materialCode + material.materialCodeVer;

                    materialMap[key] = {

                        materialCode : material.materialCode,
                        materialCodeVer : material.materialCodeVer,
                        qtyRequired : +material.qtyRequired.toFixed(4),
                    }; 
                }

                for (const column of grid.rows) {

                    let key = column.dataItem.materialCode + column.dataItem.materialCodeVer;
                    if(materialMap[key]) {

                        if(materialMap[key].qtyRequired === 0) {

                            column.dataItem.qtyConsumed = 0;
                        
                        } else {

                            if(materialMap[key].qtyRequired < + column.dataItem.inputQty.toFixed(4) ) {
                                column.dataItem.qtyConsumed = materialMap[key].qtyRequired;
                                materialMap[key].qtyRequired = 0;

                            } else {

                                column.dataItem.qtyConsumed = +(column.dataItem.inputQty.toFixed(4));
                                materialMap[key].qtyRequired = +((+(materialMap[key].qtyRequired.toFixed(4))) - (+(column.dataItem.inputQty.toFixed(4)))).toFixed(4);
                            }

                        }

                    }

                }
            });

            setLotGrid(lotGrid);
        }
    };

    const onWorkersChange = (e, option) => {
        setDisabled(false)
        setPlannedWorker(option);
    };


    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >
            <DialogHeaderWithClose>
                <DialogTitle>{t("dialog.title.Produce.completeProcess")}</DialogTitle>
            </DialogHeaderWithClose>

            <Divider />

            <DialogContent style={{ padding: "0px", height: "710px" }}>
                <ReflexContainer orientation="vertical">
                    <ReflexElement flex={0.6}>
                        <ReflexContainer orientation="horizontal">
                            <ReflexElement flex={0.6} data-cy="consumeLotList">
                                <EguLotListForProcess lotInfo={lotInfo} notifyWarning={notifyWarning} onInitialized={onLotGridInitialized} />
                            </ReflexElement>

                            <ReflexSplitter style={{ border: "none", height: "1px", color: "lightgray" }} />

                            <ReflexElement flex={0.4}>
                                <EguFailedLot notifyWarning={notifyWarning} onChangeFailedLotList={onChangeFailedLotList} />
                            </ReflexElement>
                        </ReflexContainer>
                    </ReflexElement>

                    <ReflexSplitter style={{ border: "none", width: "1px", color: "lightgray" }} />

                    <ReflexElement flex={0.4}>
                        <ReflexContainer orientation="horizontal">
                            <ReflexElement flex={0.9}>
                                <div
                                    style={{
                                        padding: "16px",
                                    }}
                                >
                                    <AutoForm
                                        id="pop-production-form"
                                        schema={schema}
                                        mode="edit"
                                        initialData={formData}
                                        onChange={onAutoFormChange}
                                        modifyFormDataCallback = {(formData, name, value) => {
                                            if(name === 'materialQty') {
                                                formData.resultQty = formData.materialQty * formData.mediumUnitQty
                                            }
                                            if(name === 'resultQty') {
                                                formData.materialQty =  Math.ceil(value / formData.mediumUnitQty);
                                            }
                                        }}
                                    />

                                    {workers ? (
                                        <FormControl style={{ margin: "1%", width: "100%" }}>
                                            <InputLabel id={id + "-label"} shrink={true} style={{ fontSize: "large", fontWeight: "bold" }}>
                                                {t("columnName.worker")}
                                            </InputLabel>
                                            <Autocomplete
                                                multiple
                                                id="worker-select"
                                                style={{ paddingTop: "16px" }}
                                                options={workers}
                                                getOptionLabel={(option) => option.label}
                                                defaultValue={plannedWorker}
                                                onChange={onWorkersChange}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </FormControl>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </ReflexElement>
                        </ReflexContainer>
                    </ReflexElement>
                </ReflexContainer>

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    /* disableWindowBlurListener = {true} */
                    onClose={onCloseNotification}
                >
                    <Alert onClose={onCloseNotification} variant="filled" severity={notification.severity}>
                        {notification.msg}
                    </Alert>
                </Snackbar>
            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                labels={[t("buttonName.completeProcessing")]}
                buttonDisabled={[disabled, false]}
                onOk={() => onOk(schema, lotInfo, lotGrid, failedLotList, materials, formData)}
                onCancel={onCancel}
            />
        </Dialog>
    );
}

export default EguPopCompleteProcessDialog;