/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

import getSchema from "../../lib/api/getSchema";
import OriginSeaweedStockExcelUploadFormDownloadButton from "../input/OriginSeaweedStockExcelUploadFormDownloadButton";
import OriginSeaweedStockExcelUploadButton from "../input/OriginSeaweedStockExcelUploadButton";

import AccessControl from "../functional/AccessControl";
import notificationState from "../../states/notificationState";
import { cloneDeep } from "lodash";

import { Grid, TextField, InputAdornment, makeStyles } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import BasicTabScreenType from "../screenTypes/functional/BasicTabScreenType";
import ExcelUploadSuccess from './ExcelUploadSuccess';
import ExcelUploadError from './ExcelUploadError';

const excelUploadSuccessViewName = "OriginSeaweedStockExcelUploadSuccessView";
const excelUploadErrorViewName = "OriginSeaweedStockExcelUploadErrorView";

const useStyle = makeStyles((theme) => ({
    root: {
        paddingTop: "8px",
        width: "120px"
    }
}));

function OriginSeaweedStockExcelUpload(props) {
    const classes = useStyle();

    const [schema, setSchema] = useState();
    const [modifySchema, setModifySchema] = useState();
    const [tab, setTab] = useState("successView");
    const [api, setApi] = useState(null);
    const [, setSearch] = useState();
    const [, setSearchVal] = useState();

    const setNotification = useSetRecoilState(notificationState);

    const {t} = useTranslation();

    useEffect(() => {
        let mounted = true;
        async function modifiedSchemaSetting() {
            let schema = await getSchema("SelectedOriginSeaweedStock");
            if(mounted) {
                let modifiedSchema = cloneDeep(schema);
                modifiedSchema.columns.forEach(column => columnSetting(column));
                setSchema(schema);
                setModifySchema(modifiedSchema);
            }
        }

        function columnSetting(column) {
            if(column.key === "PRI") {
                // 기본키 값이 없으면 안되서 필요없는 기본키값 추가
                column.defaultValue = "pri";
            }
            
            if(column.name !== "excelFile") {
                column.hideInForm = true;
                column.required = false;
            } else {
                column.hideInForm = false;
            }
        }

        modifiedSchemaSetting();

        return(() => {
            mounted = false;
        });
    }, []);

    const onApiInitialized = (api) => {
        setApi(api);
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onTabChanged = (tab) => {
        setTab(tab);
    };
    
    const onSearchInitialized = (search) => {
        setSearch(search);
        setSearchVal((searchVal) => {
            if(searchVal) {
                search.applySearch(searchVal);
            }
            return searchVal;
        } );
    }

    const onChangeSearch = (e) => {
        setSearch((search) => {
            if(search) {
                setSearchVal(e.target.value);
                search.applySearch(e.target.value);
            }
            return search;
        });
    };

    return (
        <BasicTabScreenType
            id="originSeaweedStockExcelUpload"
            tabs={["successView", "errorView"]}
            tabLabels={["엑셀 업로드 성공", "엑셀 업로드 실패"]}
            tabsStyle={{
                left: 0,
                margin: "0px",
                padding:"0px",
                height: "48px",
                width: "450px"
            }}
            onTabChanged={onTabChanged}
            value={tab}
            rightGridItems={
                [
                    <Grid item>
                        <AccessControl accessTarget={schema && schema.name} accessMethod="GET">
                            <OriginSeaweedStockExcelUploadFormDownloadButton
                                id = "OriginSeaweedStockExcelFormDownload"
                                schema = {schema}
                                refresh = {api}
                                onSuccess = {onSuccess}
                            />
                        </AccessControl>

                        <AccessControl accessTarget={modifySchema && modifySchema.name} accessMethod="POST">
                            <OriginSeaweedStockExcelUploadButton
                                id = "OriginSeaweedStockExcelUploadButton"
                                createButtonName = "excelUpload"
                                schema = {modifySchema}
                                refresh = {api}
                                onSuccess = {onSuccess}
                                modifyUrlCallback={(url) => `${url}?excelUpload`}
                            />
                        </AccessControl>
                    </Grid>,
                    <Grid item>
                        <TextField
                            id={"user-excel-upload-search"}
                            className={classes.root}
                            placeholder={t("common.search")}
                            onChange={onChangeSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>                    
                                )
                            }}
                            style={{
                                bottom: "2px",
                                width: "120px"                        
                            }}
                        />
                    </Grid>
                ]
            }
        >
            <ExcelUploadView
                viewName={tab}
                onSearchInitialized={onSearchInitialized}
                onApiInitialized={onApiInitialized}
                api={api}
            />
        </BasicTabScreenType>
    );
}

function ExcelUploadView(props) {
    const { viewName, onSearchInitialized, onApiInitialized, api } = props;

    switch(viewName) {
        case "successView":
            return <ExcelUploadSuccess 
                id='OriginSeaweedStockExcelUploadSuccessButton'
                table={excelUploadSuccessViewName}
                view={excelUploadSuccessViewName}
                onSearchInitialized={onSearchInitialized}
                onApiInitialized={onApiInitialized}
                refresh={api}
                fixedOrder={[{column: "createdAt", order: "DESC"}]}
            />

        case "errorView":
            return <ExcelUploadError
                id='OriginSeaweedStockExcelUploadErrorButton'
                table={excelUploadErrorViewName}
                view={excelUploadErrorViewName}
                onSearchInitialized={onSearchInitialized}
                onApiInitialized={onApiInitialized}
                refresh={api}
                fixedOrder={[{column: "createdAt", order: "DESC"}]}
            />

        default:
            return <></>;
    }
}

export default OriginSeaweedStockExcelUpload;
